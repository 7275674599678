html, body {
  margin: 0;
  white-space: nowrap;
  height: 100%;
  width: 100%;
}

body {
    background:grey;
    font-family: Tahoma, sans-serif;
}

.page-wrap {
    width: 1024px;
    margin: 0 auto 0 auto;
    border-left: solid;
    border-right: solid;
}

#app-parent {
    position:relative;
    border-bottom: solid;
    height: 100%;
}

.console-message {
    color: greenyellow;
}
