html, body {
  margin: 0;
}

.page-wrap {
    width: 1024px;
    /* height: 100%; */
    margin-left: auto;
    margin-right: auto;
    border-left: solid;
    border-right: solid;
}

#generic-app-parent {
  position:relative;
  /* XGA Resolution */
  width: 1024px;
  height: 728px;
  margin-left: auto;
  margin-right: auto;
  border-bottom: solid;
}

.console-message {
    color: greenyellow;
}

/****************/
/* Landing Page */
/****************/


/* This helps the margins of child elements to interact correctly with the page-wrap container */
.wrap-block {
  height: 1px;
  width: 1px;
}

.title-screen {
  background-image: url("../assets/backgrounds/title_background.png");
}

.systems-screen {
  background-image: url("../assets/backgrounds/title_background.png");
  background-blend-mode: overlay, normal;
  background-color: rgba(160 150 150 / 35%);
}

.galaxy-flexer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: white;
}

.links {
    display: flex;
    justify-content: space-around;
}

.links div {
    background-color: rgba(241, 239, 239, 0.8);
    border-radius: 10px;
    width: 260px;
    padding: 10px;
}

.links div li {
    list-style: none;
    padding: 2px;
}

.links div .header {
    margin: 0 auto;
    width: 90%;
    text-align: center;
    font-size: 1.2em;
}

.landing-menu {
  width:      240px;
  background-color: rgba(0,0,0,0.4);
  text-align: center;
  color:    white;
  margin-left:10px;
  margin-top: 10px;
}

.landing-menu .branding {
  border-bottom: solid 10px white;
  margin-bottom: 10px;
}

.landing-menu h1 {
  margin-top: 10px;
}

.landing-menu ul {
  list-style: none;
  width: 70%;
  margin: auto;
  padding: 0;
}

.landing-menu li {
  padding: 10px;
}

.landing-menu a {
  color: white;
  text-decoration: none;
}

.landing-menu a:hover {
  color: #ccc;
}
