#app-parent.system-app-parent {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

#console-div {
    position: fixed;
    margin-left: auto;
    margin-right: auto;
    width: 1050px;
    height: 20px;
    background-color: black;

    background-image: url("../assets/backgrounds/system_view_top_f.png");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
    user-select: none;
    align-self: center;
    flex: 1;
}

#console-message {
    width: 770px;
    margin-left: 40px;
}

#time {
    position: absolute;
    right: 50px;
    top: 3px;
    width: 44px;
    font-size: 0.7rem;
    text-align: right;
}

.close-system-view {
    position: absolute;
    right: 0px;
    width: 40px;
    height: 20px;
}

#ultra-container {
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

#big-container {
    height:100%;
}

.flex-container {
    display: flex;
}

#canvas-and-buttons {
    display:flex;
    width: 800px;
    flex: 3;
}

#flex-container-left {
    position: relative; /* This helps us move the minimize button in step with this panel */
    /* Flex Child Properties... This is both a flex child as well as a parent to others */
    flex: 1;

    /* Flex Container Properties */
    display: flex;
    flex-direction: column;

    margin-top: 20px;
    min-width: 224px;
    max-width: 300px;
    color: white;

    user-select: none;
    z-index: 1;
}

#flex-container-left.hidden {
    visibility: hidden;
    max-width: 224px;
}

#canvas-div canvas {
    position: fixed;
    top: 0;
    left: 0;
}

#galaxy-and-console {
    position: relative;
    height: 205px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* background: black; */

    display: flex;
    flex: 1;
    align-self: flex-end;
}

#galaxy {
    background: blue;
    border: solid 2px grey;
    height: 200px;
}

#hud {
    position: absolute;
    bottom: 210px;
    background-color: rgba(255, 255, 255, 0.1);
    right: 0px;
    color: white;
    border-radius: 15px;
    padding: 15px;
    user-select: none;
}

#hud.hud-down {
    top: auto;
    bottom: 0px;
}

#left-hud {
    position: absolute;
    bottom: 210px;
    left: 340px;
}

#left-hud:has(span.panel-minimized) {
    top: auto;
    bottom: 0px;
}

#panel-minimize-div{
    width: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

#left-panel-min-button {
    width: 20px;
    height: 20px;
    margin-left: 42px;
}

.hud-btn {
    width: 20px;
    background-color: rgba(64, 64, 64, 0.6);
    color: white;
    border-radius: 15px;
    padding: 6px;
    z-index: 1;
    user-select: none;
    text-align: center;
}

.hud-btn:hover {
    background-color: rgba(64, 64, 64, 0.8);
}

#left-panel-min-button:has(span.minimized) {
    position:relative;
    left: -210px;
    visibility: visible;
}

#lower-console-and-button {
    position: relative;
    background: #2b252f;
    border-bottom: black solid 2px;
    padding: 5px;
    height: 194px;
    opacity: 85%;
    overflow-y: scroll;
    z-index: 1;

    max-width: 650px;

    flex: 1;
}

#lower-console-and-button.expanded {
    border-left: black solid 2px;
    top: -500px;
    height: 694px;
}

#expand-button {
    display: none;
}

#lower-console {
    color: white;
    padding: 5px;
}

#lower-console-building-stats table,
#lower-console-building-stats th,
#lower-console-building-stats tr,
#lower-console-building-stats td {
    vertical-align: top;
    text-align: left;
    padding-right: 6px;
    padding-left: 6px;
}

#static-console {
    color: white;
    width: 95%;
    padding: 5px;
}

#lower-console-colony-build table,
#lower-console-colony-build th,
#lower-console-colony-build tr,
#lower-console-colony-build td {
    /* border: 1px solid black; */
    border-collapse: collapse;
    padding-right: 6px;
    padding-left: 6px;
    text-align: center;
}

.left-menu-div {
    padding-left: 25px;
    overflow-y: scroll;
    overflow-x: hidden;

    background-image: url("../assets/backgrounds/system_view_left/background.png");
    background-repeat: no-repeat;
    background-size: 100%;

    border-image-source: url("../assets/backgrounds/system_view_left/top.png");
    border-image-slice: 22 19 32 19;
    border-image-width: 23px;
    border-image-repeat: stretch;
}

.left-menu-div::-webkit-scrollbar {
    width: 10px;
    color: black;
}

.left-menu-div::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.5);
}

.left-menu-div::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5);
}

.left-menu-div h6#stars {
    margin-top: 10px;
}

.left-menu-bottom {
    height: 40px;
    background-image: url("../assets/backgrounds/system_view_left/bottom.png");
    background-repeat: round;
    background-size: 100%;

    margin-top: 20px 0 0 0;
    border-image-source: url("../assets/backgrounds/system_view_left/junction.png");
    border-image-slice: 17 0 0 0;
    border-image-width: 23px;
    border-image-outset:20px;
    border-image-repeat: stretch;
}

#btnGovernor {
    margin-top: 7px;
    width: 59%;
    height: 66%;
    margin-left: 32%;
    color: rgb(105, 0, 38);
    font-size:18px;
    font-weight:bold;

    background-image: url("../assets/backgrounds/system_view_left/button.png");
    background-repeat: round;
    background-size: 100%;
    border-color: #a7a8a7;
    border-width: 2px 2px;
}

ul.sidebar-list {
    list-style-type: none;
    padding: 0;
    padding-left: 10px;
    padding-right: 10px;

    margin: 0px;
    color: white;
}

#sidebar-list li {
    margin-bottom: 20px;
}

.selected-thumbnail {
    background: orange;
}

.left-menu-thumbnail {
    display: flex;
    align-items: flex-end;
    position: relative;
    height: 45px;
    padding-top: 5px;
    padding-bottom: 5px;
    overflow:hidden;
}

.left-menu-thumbnail img {
    align-self: flex-start;
    width: 40px;
    height: 40px;
}

.left-menu-thumbnail label {
    width: 75px;
    white-space: nowrap;
}

.left-menu-thumbnail .right-side {
    align-self: flex-start;
    height: 100%;
    display: flex;
    margin-left: 16px;
}

.left-menu-thumbnail .wealth-rating {
    width: 54px;
    margin-left: -57px;
    align-self: flex-start;
    text-align: right;
    white-space: nowrap;

}

.left-menu-thumbnail .habitat-column {
    align-self: flex-end;
    width: 45px;
}
