::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.chat-header {
    color: white;
}

/* Lobby container */
.chat-lobby {
    color: white;
    max-width: 600px;
    margin: 5% auto;
    margin: 10px auto 20px auto;
    border: 2px solid #5A6D8D;
    border-radius: 8px;
    background-color: rgba(10, 20, 40, 0.8); /* A dark semi-transparent background */
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    padding: 20px;
}

.chat-lobby-header {
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 10px;
    border-bottom: 2px solid #5A6D8D;
    padding-bottom: 10px;
    color: #FFD700; /* Golden for a highlighted touch */
}

.chat-users {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    height: 40px;
}

.chat-user {
    flex: 1;
    text-align: center;
    padding: 5px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.5);
    margin: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.chat-messages {
    height: 250px;
    overflow-y: scroll; /* Scroll for messages */
    margin-bottom: 20px;
    border: 1px solid #5A6D8D;
    border-radius: 5px;
}

.chat-message {
    padding: 10px;
    border-bottom: 1px solid #5A6D8D;
}

.chat-message:last-child {
    border-bottom: none;
}

.chat-message-user {
    font-weight: bold;
    margin-bottom: 5px;
}

.chat-input {
    display: flex;
}

.chat-input input {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 4px 0 0 4px;
    color: #000;
}

.chat-input button {
    padding: 10px 20px;
    border: none;
    border-radius: 0 4px 4px 0;
    background-color: #FFD700; /* Golden for a highlighted touch */
    cursor: pointer;
    transition: background 0.3s;
}

.chat-input button:hover {
    background-color: #E0B400;
}

.chat-lobby-controls {
    border: 2px solid #5A6D8D;
    border-radius: 8px;
    background-color: rgba(10, 20, 40, 0.8); /* A dark semi-transparent background */
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    padding: 20px;
    padding-bottom: 32px;
    margin: 20px auto 20px auto;
    width: 600px;
    height: 50px;
}

.flexy-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-lobby-input {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 4px 0 0 4px;
    color: #000;
}

.chat-lobby-title {
    font-size: 1.5em;
    text-align: center;
    border-bottom: 2px solid #5A6D8D;
    color: #FFD700; /* Golden for a highlighted touch */
}

.play-button {
    display: inline-block;
    padding: 5px 15px; /* Adds padding around the text to create a button-like appearance */
    background-color: #FFD700; /* Yellow background color */
    border-radius: 0px 15px; /* Rounded corners */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for a 3D effect */
}

.play-button:hover {
    background-color: #E0B400; /* Darker yellow on hover for interactive feedback */
    text-decoration: none; /* Removes underline on hover */
    color: #000; /* Dark text color on hover for better contrast */
}
